<template>
	<v-card class="mt-2 mb-5 pb-2" flat color="transparent" rounded="xl">
		<FeedNotices :notices="notices" :loading-parent="loading || loadingParent" />
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EventFeedNotices',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	components: {
		FeedNotices: () => import('@/components/feed/FeedNotices.vue'),
	},
	computed: {
		...mapGetters({
			event: 'events/event',
			notices: 'feed/notices',
		}),
	},
	watch: {
		event() {
			this.loading = true
			this.fetchNoticesEvent({ eventID: this.event.id }).then(() => {
				this.loading = false
			})
		},
	},
	created() {
		this.loading = true
		this.fetchNoticesEvent({ eventID: this.event.id }).then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('feed', ['fetchNoticesEvent']),
	},
}
</script>
